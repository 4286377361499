import React, { ReactElement, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Alert,
    AppBar,
    ArrowDropDownIcon,
    ArrowDropUpIcon,
    Box,
    Button,
    DownloadIcon,
    Grid,
    HelpOutlineIcon,
    IconButton,
    Logo,
    Menu,
    MenuIcon,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from '@methanesat/ui-components';

import LogOutButton from './LogOutButton';
import { useLocaleRoute, useTranslate } from '../../hooks';
import { setGlobalDate, setEmissionsMapViewState, setPlatform, selectPlatform } from '../../reducers';
import { Platforms } from '../../types';
import Link from '../../components/Link';
import {
    SHOW_DESKTOP_HAMBURGER_MENU,
    INTERNAL_ONLY_SIGNAGE,
    SHOW_LOGOUT_BUTTON,
    HEADER_MSAT_PLATFORM_DROPDOWN_ENABLED
} from '../../environmentVariables';
import { getDefaultGlobalDate, initialViewStates, METHANESAT_FAQ_URL } from '../../consts';

/**
 * Universal page header.
 * @todo Add global CSS, componentDidCatch for error handling, persisting some layout
 * between pages (header?).
 */
const Header = (): ReactElement => {
    const generateLocaleRoute = useLocaleRoute();
    const t = useTranslate();
    const dispatch = useDispatch();

    // TODO: DP-731 Remove useMediaQuery once issue with
    // multiple SVGs is resolved (DP-726)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const anchorElement = useRef<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const selectedPlatform = useSelector(selectPlatform);

    const handleClick = () => {
        setAnchorEl(anchorElement.current);
    };

    const handlePlatformSelection = (platform: Platforms) => {
        dispatch(setPlatform(platform));
        dispatch(setEmissionsMapViewState(initialViewStates[platform]));
        dispatch(setGlobalDate(getDefaultGlobalDate(platform).value));
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [hamburgerAnchorEl, setHamburgerAnchorEl] = React.useState<null | HTMLElement>(null);

    const hamburgerAnchorElement = useRef<null | HTMLElement>(null);

    const openHamburger = Boolean(hamburgerAnchorEl);

    const handleHamburgerClick = () => {
        setHamburgerAnchorEl(hamburgerAnchorElement.current);
    };

    const handleHamburgerClose = () => {
        setHamburgerAnchorEl(null);
    };

    return (
        <>
            <AppBar position="static" color="inherit" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs="auto">
                            <Link data-testid="logo" href="https://www.methanesat.org/" tabIndex={0} target="_blank">
                                <Logo />
                            </Link>
                        </Grid>
                        {INTERNAL_ONLY_SIGNAGE ? (
                            <Grid item xs="auto">
                                <Alert
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                    variant="filled"
                                    severity="warning"
                                >
                                    <Grid container display="flex">
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: { xs: 'none', sm: 'none', md: 'flex' },
                                                paddingLeft: 1,
                                                paddingRight: 1
                                            }}
                                        >
                                            INTERNAL USE ONLY: DO NOT DISTRIBUTE
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: { xs: 'flex', sm: 'flex', md: 'none' }
                                            }}
                                        >
                                            INTERNAL USE ONLY
                                        </Typography>
                                    </Grid>
                                </Alert>
                            </Grid>
                        ) : null}
                        <Grid item xs="auto">
                            <Grid container alignItems="center">
                                <Grid item xs="auto">
                                    <Box component="div" margin={0} ref={anchorElement}>
                                        {/* desktop instrument menu */}
                                        <Button
                                            data-testid="instrument-menu-selector"
                                            id="instrument-menu-selector"
                                            aria-label={t('header.ariaMenuLabel')}
                                            onClick={handleClick}
                                            color="inherit"
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: INTERNAL_ONLY_SIGNAGE ? 'none' : 'flex',
                                                    md: 'flex'
                                                },
                                                textTransform: 'none'
                                            }}
                                        >
                                            {selectedPlatform === Platforms.MAIR
                                                ? t(`platforms.MethaneAIR.long`)
                                                : t(`platforms.MethaneSAT.long`)}
                                            {open ? (
                                                <ArrowDropUpIcon></ArrowDropUpIcon>
                                            ) : (
                                                <ArrowDropDownIcon></ArrowDropDownIcon>
                                            )}
                                        </Button>
                                        {/* 
                                          desktop & mobile menu - 
                                            desktop: displays instruments only
                                            mobile: displays instruments & other page links
                                         */}
                                        <Menu
                                            id="app-header-menu"
                                            MenuListProps={{ 'aria-labelledby': 'app-menu-selector' }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    width: '13rem'
                                                }
                                            }}
                                        >
                                            {/* desktop & mobile */}
                                            <MenuItem
                                                data-testid="methaneair-dropdown-option"
                                                onClick={() => handlePlatformSelection(Platforms.MAIR)}
                                            >
                                                {t('platforms.MethaneAIR.long')}
                                            </MenuItem>
                                            {/* desktop & mobile */}
                                            <MenuItem
                                                data-testid="methanesat-dropdown-option"
                                                disabled={!HEADER_MSAT_PLATFORM_DROPDOWN_ENABLED}
                                                onClick={() => handlePlatformSelection(Platforms.MSAT)}
                                            >
                                                {t('platforms.MethaneSAT.long')}
                                            </MenuItem>
                                            {/* mobile only links */}
                                            <Link
                                                data-testid="emissions-map-link-mobile"
                                                href={generateLocaleRoute('/emissions-map')}
                                            >
                                                <MenuItem
                                                    sx={{
                                                        display: { xs: 'flex', sm: 'none' },
                                                        borderTop: '1px solid rgba(0, 0, 0, 0.12);'
                                                    }}
                                                >
                                                    {t('header.emissionsMap')}
                                                </MenuItem>
                                            </Link>
                                            <Link
                                                data-testid="landing-page-mobile"
                                                href={generateLocaleRoute('/landing')}
                                            >
                                                <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                                    {t('header.landingPageLabel')}
                                                </MenuItem>
                                            </Link>
                                            <Link data-testid="faq-mobile" href={generateLocaleRoute('/faq')}>
                                                <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                                    {t('header.faqLabel')}
                                                </MenuItem>
                                            </Link>
                                            <Link
                                                data-testid="methodology-mobile"
                                                href={generateLocaleRoute('/methodology')}
                                            >
                                                <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                                    {t('header.methodology')}{' '}
                                                </MenuItem>
                                            </Link>
                                            <Link
                                                data-testid="terms-of-use-mobile"
                                                href={generateLocaleRoute('/terms-of-use')}
                                            >
                                                <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                                    {t('header.termsOfUse')}
                                                </MenuItem>
                                            </Link>
                                            <Link
                                                data-testid="privacy-policy-mobile"
                                                href={generateLocaleRoute('/privacy-policy')}
                                            >
                                                <MenuItem sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                                    {t('header.privacyPolicy')}{' '}
                                                </MenuItem>
                                            </Link>
                                            {/* mobile logout */}
                                            {SHOW_LOGOUT_BUTTON && (
                                                <MenuItem
                                                    disableGutters
                                                    dense
                                                    sx={{
                                                        display: { xs: 'flex', sm: 'none' },
                                                        borderTop: '1px solid rgba(0, 0, 0, 0.12);'
                                                    }}
                                                >
                                                    <LogOutButton
                                                        data-testid="log-out-mobile"
                                                        disableRipple
                                                        fullWidth
                                                    />
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </Box>
                                </Grid>
                                {/* desktop logout */}
                                {SHOW_LOGOUT_BUTTON && (
                                    <Grid
                                        item
                                        xs="auto"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: INTERNAL_ONLY_SIGNAGE ? 'none' : 'block',
                                                md: 'block'
                                            }
                                        }}
                                    >
                                        <LogOutButton data-testid="log-out-desktop" />
                                    </Grid>
                                )}
                                <Grid>
                                    <Tooltip title={t('header.downloadLabel')} sx={{ marginLeft: 1, fontSize: 18 }}>
                                        <IconButton
                                            aria-label={t('header.downloadLabel')}
                                            data-testid="download-button"
                                            sx={{ paddingBottom: { xs: '4px', sm: '0px' }, color: 'inherit' }}
                                        >
                                            <Link
                                                data-testid="download-link"
                                                href={
                                                    'https://developers.google.com/earth-engine/datasets/tags/methaneair'
                                                }
                                                target={'_blank'}
                                            >
                                                <DownloadIcon />
                                            </Link>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('header.faqLabel')} sx={{ marginLeft: 1, fontSize: 18 }}>
                                        <IconButton
                                            aria-label={t('header.faqLabel')}
                                            data-testid="faq-button"
                                            href={METHANESAT_FAQ_URL}
                                            sx={{ paddingBottom: { xs: '12px', sm: '8px' }, color: 'inherit' }}
                                            target="_blank"
                                        >
                                            <HelpOutlineIcon id="header-faq-button" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* mobile hamburger-button */}
                                <IconButton
                                    data-testid="app-menu-selector-mobile"
                                    aria-label={t('header.ariaMenuLabel')}
                                    id="app-menu-selector"
                                    aria-controls={open ? 'app-header-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    sx={{ display: { xs: 'block', sm: 'none' }, color: 'inherit' }}
                                >
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                                {/* desktop hamburger button & menu */}
                                {SHOW_DESKTOP_HAMBURGER_MENU && (
                                    <Grid item xs="auto" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <Box component="div" margin={0} ref={hamburgerAnchorElement}>
                                            {/* desktop navigation menu */}
                                            <IconButton
                                                onClick={handleHamburgerClick}
                                                data-testid="app-menu-selector"
                                                sx={{ color: 'inherit' }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                            <Menu
                                                open={openHamburger}
                                                anchorEl={hamburgerAnchorEl}
                                                onClose={handleHamburgerClose}
                                                PaperProps={{
                                                    style: {
                                                        width: '13rem'
                                                    }
                                                }}
                                            >
                                                <Link
                                                    data-testid="emissions-map-page"
                                                    href={generateLocaleRoute('/emissions-map')}
                                                >
                                                    <MenuItem>{t('header.emissionsMap')}</MenuItem>
                                                </Link>
                                                <Link data-testid="landing-page" href={generateLocaleRoute('/landing')}>
                                                    <MenuItem>{t('header.landingPageLabel')}</MenuItem>
                                                </Link>
                                                <Link data-testid="faq-page" href={generateLocaleRoute('/faq')}>
                                                    <MenuItem>{t('header.faqLabel')}</MenuItem>
                                                </Link>
                                                <Link
                                                    data-testid="methodology-page"
                                                    href={generateLocaleRoute('/methodology')}
                                                >
                                                    <MenuItem>{t('header.methodology')}</MenuItem>
                                                </Link>
                                                <Link
                                                    data-testid="terms-of-use-page"
                                                    href={generateLocaleRoute('/terms-of-use')}
                                                >
                                                    <MenuItem>{t('header.termsOfUse')}</MenuItem>
                                                </Link>
                                                <Link
                                                    data-testid="privacy-policy-page"
                                                    href={generateLocaleRoute('/privacy-policy')}
                                                >
                                                    <MenuItem>{t('header.privacyPolicy')}</MenuItem>
                                                </Link>
                                            </Menu>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
