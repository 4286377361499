import React, { ElementType, ForwardedRef, forwardRef } from 'react';

import { default as UnstyledGrid } from '@mui/material/Grid';

import type { GridProps as UnstyledGridProps } from '@mui/material/Grid';

export interface GridProps extends UnstyledGridProps {
    ellipsis?: boolean;
    component?: ElementType;
}

const GridWithEllipsis = ({ sx, ...restProps }: GridProps) => (
    <UnstyledGrid
        sx={[
            ...(Array.isArray(sx) ? sx : [sx]),
            {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
            }
        ]}
        {...restProps}
    />
);

const Grid = forwardRef(function GridRef({ ellipsis, ...gridProps }: GridProps, ref: ForwardedRef<HTMLDivElement>) {
    return ellipsis ? <GridWithEllipsis {...gridProps} /> : <UnstyledGrid {...gridProps} ref={ref} />;
});

export default Grid;
