import React, { ReactElement } from 'react';
import NextHead from 'next/head';

import { methaneSATMeta } from '../../consts';

/**
 * React props for the Head component.
 */
export type HeadProps = {
    /** Children tags to add to page <head>. */
    children?: ReactElement;
    /** Page description (shows up underneath title in search engines). */
    description?: string;
    /** Image preview for social media meta tags. */
    image?: string;
    /** Page/tab title. */
    title: string;
};

/**
 * Per-page customized HTML <head> content for social sharing, favicons, SEO content, etc.
 * Note that <head> contents that are not customized per page live in /pages/_document.page.tsx
 * @todo rel=canonical
 */
const Head = ({
    title,
    description = methaneSATMeta.description,
    image = methaneSATMeta.image,
    children
}: HeadProps): ReactElement => {
    return (
        <NextHead>
            <title>{title}</title>
            <meta content={description} name="description" />
            <meta httpEquiv="content-language" content="en" />
            <meta name="theme-color" content="#ffffff" />
            {/*
             * Facebook Open Graph
             * Debugger: https://developers.facebook.com/tools/debug/
             */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="MethaneSAT" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:url" content={image} />
            {/*
             * Twitter Cards
             * Debugger: https://cards-dev.twitter.com/validator
             */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:site" content="@MethaneSAT" />
            <meta name="twitter:creator" content="@MethaneSAT" />
            {/*
             * Schema.org
             * Debugger: https://search.google.com/test/rich-results
             */}
            <meta itemProp="name" content={title} />
            <meta itemProp="headline" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={image} />
            {children}
        </NextHead>
    );
};

export default Head;
