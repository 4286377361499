import { ReactElement, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { Link as MSATLink } from '@methanesat/ui-components';

export interface LinkProps extends NextLinkProps {
    children?: ReactNode;
    href: string;
    tabIndex?: number;
    target?: string;
    underline?: 'none' | 'hover' | 'always' | undefined;
}

/**
 * Links that allow for client-side transitions between routes.  Convenience wrapper that combines MUI Link with Next.js
 * Link.
 */
export const Link = (props: LinkProps): ReactElement => {
    const { children, ...otherProps } = props;

    return (
        // eslint-disable-next-line test-selectors/anchor
        <MSATLink component={NextLink} {...otherProps}>
            {children}
        </MSATLink>
    );
};

export default Link;
