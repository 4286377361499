import { Button, ButtonProps } from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';

interface LogOutButtonProps extends ButtonProps {
    'data-testid': string;
}

/**
 * Button to log out of the app through Google Identity-Aware Proxy
 * https://cloud.google.com/iap/docs/external-identity-sessions#signing_users_out
 */
const LogOutButton = ({ 'data-testid': dataTestId, sx, ...otherProps }: LogOutButtonProps) => {
    const t = useTranslate();

    return (
        <Button
            data-testid={dataTestId}
            href="/?gcp-iap-mode=GCIP_SIGNOUT"
            sx={[{ color: 'text.primary' }, ...(Array.isArray(sx) ? sx : [sx])]}
            {...otherProps}
        >
            {t('header.logOut')}
        </Button>
    );
};

export default LogOutButton;
